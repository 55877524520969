import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-bottom": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置 ")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticClass: "tableCard",
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["commodity:btn:add"],
      expression: "['commodity:btn:add']"
    }],
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.createLang();
      }
    }
  }, [_vm._v("创建")]), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "platform" ? _c("span", [_c("span", [_vm._v(_vm._s(record.platform == 1 ? "IOS" : "Android"))])]) : key == "action" ? _c("span", [_c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDetail(record.id);
              }
            }
          }, [_vm._v("详情")]), _c("span", {
            directives: [{
              name: "hasPermission",
              rawName: "v-hasPermission",
              value: ["brand:index:delete"],
              expression: "['brand:index:delete']"
            }]
          }, [_c("a-divider", {
            attrs: {
              type: "vertical"
            }
          }), _c("a", {
            on: {
              click: function click($event) {
                return _vm.handleDelete(record.id);
              }
            }
          }, [_vm._v("删除")])], 1)]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "创建",
      visible: _vm.visible,
      "confirm-loading": _vm.confirmLoading
    },
    on: {
      ok: _vm.handleSave,
      cancel: _vm.cancel
    }
  }, [_c("div", [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.creationForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "平台"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["platform", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                        'platform',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    }
  }, _vm._l(_vm.getDataWithKey("platform"), function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "版本",
      prop: "version"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["version", {
        rules: [{
          required: true,
          message: "请输入版本"
        }]
      }],
      expression: "[\n                        'version',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请输入版本',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      placeholder: "请输入版本"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "语言"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["language", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                        'language',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    }
  }, _vm._l(_vm.getDataWithKey("language"), function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1)]), _c("DelUpdate", {
    attrs: {
      tipsDesc: _vm.tipsDesc,
      title: _vm.title,
      loading: _vm.loading,
      isDelete: _vm.isDelete,
      comVisible: _vm.isDeleteVisible
    },
    on: {
      cancle: _vm.handleCancle,
      submit: _vm.handleSubmit
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };