var columns = [{
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  // width: '7%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: '平台',
  dataIndex: 'platform',
  key: 'platform',
  // width: '7%',
  scopedSlots: {
    customRender: 'platform'
  }
}, {
  title: '版本',
  dataIndex: 'version',
  key: 'version',
  // width: '10%',
  scopedSlots: {
    customRender: 'version'
  }
}, {
  title: '语言',
  dataIndex: 'language',
  key: 'language',
  // width: '10%',
  scopedSlots: {
    customRender: 'language'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };