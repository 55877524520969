var searchKeys = [{
  key: "platform",
  label: "平台",
  placeholder: "请选择平台",
  required: false,
  rules: [],
  select: true,
  allowClear: true
}, {
  key: "version",
  label: "版本",
  placeholder: "请输入版本",
  required: false,
  rules: [],
  input: true
}, {
  key: "language",
  label: "语言",
  placeholder: "请输入语言",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };